import React, { useEffect } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import "aos/dist/aos.css"
//import { parse } from "tldts"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import Section from "../components/Section"
import SectionTitle from "../components/SectionTitle"
import BodyText from "../components/BodyText"
import Columns from "../components/Columns"
import Column from "../components/Column"
import FeaturedProject from "../components/FeaturedProject"
import IndieLink from "../components/IndieLink"

const HomePageTemplate = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  const {
    seo,
    pageSettings: { showFooter },
    headerSettings: { headerImages, title, subTitle },
    homepageSettings: {
      intro: { title: introTitle, content: introContent },
      featuredProject: { title: projectTitle },
      whatWeDo: { title: whatWeDoTitle, content: whatWeDoContent },
    },
  } = page

  return (
    <Layout showMenu={false} showFooter={showFooter} page={page}>
      <Seo seo={seo} />
      <MountainHeader headerImages={headerImages} />
      <PageWrapper additionalClassName="home">
        <PageTitleSection title="T&C" subtitle="" />

        <Section dataAos="fade-up" additionalClasses="double-top-padding">
          <SectionTitle size="is-size-2 is-size-2-mobile">
            INDIE RIDGE - WEBSITE HOSTING AND SUPPORT TERMS OF SERVICE
          </SectionTitle>
          <Columns mobile={true}>
            <Column size={{ all: 9 }}>
              <BodyText
                text=""
                weight="bold"
                padding={true}
                size="is-size-5 is-size-3-mobile"
              />
              <div className="content has-text-white">
                <p className="is-size-4">Last Revised: 16/10/2022</p>
                <p className="is-size-5">
                  The following terms and conditions (“Terms”) govern all use of
                  the Indie Ridge website Hosting and Support Services
                  including, but not limited to, Managed Hosting, Premium
                  Support with Unlimited Small Changes, and our Hosting Backup,
                  (taken together, our “Services”). Our Services are offered
                  subject to your acceptance without modification of all of the
                  terms and conditions contained herein and all other operating
                  rules, policies (including, without limitation, Indie Ridge’s
                  Privacy Policy and procedures that may be published from time
                  to time by Indie Ridge (collectively, the “Agreement”). You
                  agree that we may automatically upgrade our Services, and
                  these Terms will apply to any upgrades.
                </p>
                <p className="is-size-5">
                  Please read this Agreement carefully before accessing or using
                  our Services. By accessing or using any part of our Services,
                  you agree to become bound by the Terms of this Agreement. If
                  you do not agree to all the Terms of this Agreement, then you
                  may not access or use any of our Services. If these Terms are
                  considered an offer by Indie Ridge, acceptance is expressly
                  limited to these Terms.
                </p>
                <h2 className="is-size-4 has-text-white">
                  1. DESCRIPTION OF SERVICES{" "}
                </h2>
                <ul>
                  <li>
                    {" "}
                    Managed Hosting: Managed Hosting gives you a streamlined and
                    optimised service where Indie Ridge manages the hosting of
                    your website. We handle all of the hosting administrative
                    tasks, including configuring and managing WPMUDEV and Gatsby
                    Cloud Services, automated daily backups, WordPress CMS core
                    updates and server-level caching.
                  </li>
                  <li>
                    Premium Support with Unlimited Small Changes: Premium
                    Support with Unlimited Small Changes is a premium support
                    service available for a set monthly fee or a discounted
                    annual fee. The Premium Support with Unlimited Small Changes
                    package includes Managed Hosting and Hosting Backup with
                    additional assistance if you need an experienced server
                    administrator for complicated tasks, including optimising
                    databases, configuring firewalls, moving content or making
                    unlimited number of small changes to the website.
                  </li>
                  <li>
                    Hosting Backup: Hosting backup saves your website files to
                    the cloud once per day. Backup and restore services are
                    included with Managed Hosting and will be cancelled if the
                    Premium Support with Unlimited Small Changes plan is
                    cancelled.
                  </li>
                  <li>
                    Custom Technical Service. Additional website design and
                    development work that falls outside of the scope of Premium
                    Support with Unlimited Small Changes for example the design
                    and build of new website pages, animations, navigation and
                    functionality.
                  </li>
                </ul>
                <h2 className="is-size-4 has-text-white">2. MANAGED HOSTING</h2>
                <p>
                  If you are using our managed hosting services, the following
                  applies:
                </p>
                <ul>
                  <li>
                    By using our hosting services, you grant us access to your
                    website and database for the purpose of hosting and/or
                    backing up your content.
                  </li>
                  <li>
                    In order to address security vulnerabilities, we will first
                    notify you, and then we may push an upgrade to your site, or
                    we may access your site to remove malicious code.
                  </li>
                  <li>
                    We may scan your data and content to compile
                    aggregated/anonymized statistics for our internal use to
                    optimize the performance and security of the service.
                  </li>
                  <li>
                    If you lose access to your hosting account or cancel your
                    membership, you will not be able to access your hosting or
                    backup data. We may not be able to recover backups or data
                    more than fourteen (14) days after the account goes
                    inactive.
                  </li>
                  <li>
                    You grant us a worldwide, royalty-free, and non-exclusive
                    license to copy and store your data and content only to the
                    extent necessary to operate the Service. You own your data
                    and content.
                  </li>
                </ul>
                <p>
                  For sites that we host, our goal is an uptime of 100% -
                  meaning all sites are available 24/7/365. We know that
                  sometimes this goal may not be achieved, and in those cases,
                  the following Service Level Agreement (SLA) applies:
                </p>
                <ul>
                  <li>We guarantee a 99.9% uptime SLA.</li>
                  <li>
                    We do not place any hard limits on traffic, visits, or
                    bandwidth on your sites. Should your site experience a spike
                    in traffic (both human and/or by bots), your site may
                    experience performance problems or even downtime. We will
                    send an alert on how to upgrade your hosting plan to higher
                    resources in this case, and our SLA will not apply.
                  </li>
                  <li>
                    Should the SLA not be met during a calendar month, you are
                    entitled to an SLA Credit. You must contact support to
                    request an SLA Credit within 30 days of the end of the month
                    for which the SLA was not met. Credits will be valued at 5%
                    of your monthly hosting charges for each full hour of
                    downtime beyond the SLA and will not exceed 100% of your
                    hosting charges. Downtime will be determined by our
                    monitoring tools.
                  </li>
                  <li>
                    Our SLA applies to any human errors by our team and by
                    employees or contractors of any 3rd party service providers
                    that we use to supply the hosting service.
                  </li>
                  <li>
                    Our SLA does not apply to any downtime that is out of our
                    control, including problems resulting from 3rd party code,
                    user-error, or natural disasters such as floods or
                    earthquakes.
                  </li>
                </ul>
                <h2 className="is-size-4 has-text-white">
                  3. PREMIUM SUPPORT WITH UNLIMITED SMALL CHANGES
                </h2>
                <p>
                  If you elect to use our Premium Support with Unlimited Small
                  Changes (either as a subscription or as an annual purchase),
                  we will charge monthly or yearly in advance depending on your
                  chosen option.
                </p>
                <ul>
                  <li>
                    Unlimited Small Change means we agree to make any changes to
                    any current pages, posts, items, plugins, themes at your
                    request. This does not include any custom coding or system
                    updates (plugins, themes, etc){" "}
                  </li>
                  <li>
                    {" "}
                    We make every effort to stick within 3-5 business days for
                    Unlimited Small Changes to be made. However larger projects
                    can take longer than the timeframe mentioned. We will always
                    advise you ahead of time with an estimated timeframe.
                  </li>
                  <li>
                    All services will be listed as a "Best Effort Service." We
                    will do our utmost to support anything WordPress and Gatsby
                    related; however, some requests may require complex or
                    custom work which is beyond the scope of what we can
                    reasonably provide. We reserve the right to determine which
                    requests and levels of support and small changes that we can
                    and can’t provide.
                  </li>
                  <li>
                    If we determine that any support or Unlimited Small Change
                    request falls outside the scope of your plan, you can
                    request Custom Technical Service for a fee, or on a per
                    service basis, which we will quote to you before providing
                    the Custom Technical Service.
                  </li>
                </ul>
                <h2 className="is-size-4 has-text-white">4. HOSTING BACKUP</h2>
                <ul>
                  <li>
                    All hosting plans include nightly Hosting backups with no
                    storage costs. Hosting backups can be stored for up to 30
                    days. Hourly Hosting backup services can be purchased for
                    individual sites at an additional cost.
                  </li>
                  <li>
                    We may scan your data and content to compile
                    aggregated/anonymized statistics for our internal use to
                    optimize the performance and security of the service.
                  </li>
                  <li>
                    If you lose access to your account or cancel your
                    subscription, you will not be able to access your backup
                    data. We may not be able to recover backups or data more
                    than fourteen (14) days after the account goes inactive.
                  </li>
                  <li>
                    You grant us a worldwide, royalty-free, and non-exclusive
                    license to copy and store your data and content only to the
                    extent necessary to operate the Service. You own your data
                    and content.
                  </li>
                </ul>
                <h2 className="is-size-4 has-text-white">5. PROHIBITED USES</h2>
                <p>
                  When using Indie Ride Hosting and Support Services, you agree
                  not to:
                </p>
                <ul>
                  <li>violate any UK laws or foreign laws or regulations;</li>
                  <li>
                    infringe on any intellectual property rights (e.g.,
                    copyright, trademark, patent or other proprietary rights);
                  </li>
                  <li>
                    publish, host, or transmit content that is defamatory,
                    slanderous or trade libellous;
                  </li>
                  <li>
                    publish, host, or transmit content that is threatening or
                    harassing.
                  </li>
                  <li>
                    publish, host, or transmit content that is discriminatory
                    based on gender, sexual orientation, race, age or promotes
                    hate;
                  </li>
                  <li>
                    disclose the sensitive personal information of others;
                  </li>
                  <li>
                    distribute material that is or enables malware, spyware,
                    adware, or other malicious code;
                  </li>
                  <li>send spam or bulk unsolicited messages;</li>
                  <li>
                    interfere with, disrupt, or attack any service or network;
                  </li>
                  <li>resell, share, or publish credentials or access.</li>
                </ul>
                <h2 className="is-size-4 has-text-white">
                  6. PAYMENTS AND SUBSCRIPTIONS
                </h2>
                <ul>
                  <li>
                    Our Services require an active paid subscription - including
                    Managed Hosting, Premium Support with Unlimited Small
                    Changes and Hosting Backup (“Paid Services”). By using a
                    Paid Service, you agree to pay the specified fees, which
                    we’ll charge you for in regular intervals (such as monthly
                    or annually), on a pre-pay basis until you cancel, which you
                    can do at any time by contacting support.
                  </li>
                  <li>
                    To ensure uninterrupted service, our Paid Services are
                    automatically renewed. This means that unless you cancel a
                    Paid Service before the end of the applicable subscription
                    period, it will automatically renew, and you authorize us to
                    invoice you or use any payment mechanism we have on record
                    for you to collect the then-applicable subscription fee (as
                    well as any taxes). Your Paid Services are renewed for the
                    same interval of time.
                  </li>
                  <li>
                    If you choose to cancel your subscription, your membership
                    will remain active until the end of your current billing
                    cycle.
                  </li>
                  <li>
                    If your payment fails or Paid Services are otherwise not
                    paid for on time, we may immediately cancel or revoke your
                    access to the Paid Services.
                  </li>
                  <li>
                    We reserve the right to refuse service or cancel your
                    subscription and revoke membership access with a prorated
                    refund for any reason we deem appropriate.
                  </li>
                  <li>
                    We may change our fees at any time. When applicable, we may
                    give you advance notice of the fee changes. If you don’t
                    agree with the fee changes, you can cancel your Paid
                    Service.
                  </li>
                </ul>
                <h2 className="is-size-4 has-text-white">REFUNDS</h2>
                <ul>
                  <li>
                    If you are experiencing a technical issue, before requesting
                    a refund, let our support team help resolve the issue by
                    contacting support.
                  </li>
                  <li>
                    No refunds whatsoever will be given for scheduled
                    subscription payments after 30 days, except in the rare case
                    that it is due to billing errors on our end.
                  </li>
                  <li>
                    If you contact your bank or credit card company to decline
                    or reverse the charge of fees for Paid Services, we will not
                    provide a refund and we may revoke your access to our
                    Services in general.
                  </li>
                  <li>
                    Refunds are available once per person or entity and will not
                    apply if your account is in violation of these Terms.
                  </li>
                </ul>
                <h2 className="is-size-4 has-text-white">8. LEGAL NOTICES</h2>
                <p>
                  {" "}
                  Our Services are provided “as is.” Indie Ridge and its
                  suppliers and licensors hereby disclaim all warranties of any
                  kind, express or implied, including, without limitation, the
                  warranties of merchantability, fitness for a particular
                  purpose and non-infringement. Neither Indie Ridge nor its
                  suppliers and licensors, makes any warranty that our Services
                  will be error free or that access thereto will be continuous
                  or uninterrupted. You understand that you subscribe to our
                  Services at your own discretion and risk.
                </p>
                <p>
                  {" "}
                  This agreement and the Terms shall be governed by and subject
                  to the laws of England and Wales and all disputes which may
                  arise out of or in connection with this agreement or the Terms
                  shall be subject to the exclusive jurisdiction of the courts
                  of England. A person who is not a party to this agreement has
                  no right under the Contracts (Rights of Third Parties) Act
                  1999 (the Act) to enforce any term of this agreement or the
                  Terms, but this provision does not affect any right or remedy
                  of any third party which exists or is available apart from
                  under the Act.
                </p>
                <p>
                  {" "}
                  In no event will Indie Ridge, or its suppliers or licensors,
                  be liable with respect to any subject matter of this Agreement
                  under any contract, negligence, strict liability or other
                  legal or equitable theory for: (i) any special, incidental or
                  consequential damages; (ii) the cost of procurement for
                  substitute products or services; (iii) for interruption of use
                  or loss or corruption of data; or (iv) for any amounts that
                  exceed the fees paid by you to Indie Ridge under this
                  Agreement during the twelve (12) month period prior to the
                  cause of action. Indie Ridge shall have no liability for any
                  failure or delay due to matters beyond their reasonable
                  control. The foregoing shall not apply to the extent
                  prohibited by applicable law.
                </p>
              </div>
            </Column>
          </Columns>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default HomePageTemplate

export const pageQuery = graphql`
  query HomePageById {
    # selecting the current post by id
    page: wpPage(id: { eq: "cG9zdDozNQ==" }) {
      # use common page fields from fragment @ src/fragments.js
      ...pageFields
      homepageSettings {
        featuredProject {
          title
        }
        intro {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
        whatWeDo {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
      }
    }
  }
`
